<template>
  <div>
    <HeaderPublic><GTM></GTM></HeaderPublic>
    <div id="account-credentials-content" class="container-sm mt-sm-5">
      <div class="border">
        <div id="account-credentials-form">
          <h1 class="change-password-header">Reset Password</h1>
          <div class="reset-password-message">
            <div>
              <b
                ><a
                  href="https://uat.account.texasoncourse.org/passwords/reset"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="change-password-link"
                  >Click here</a
                ></b
              >
              to change your password.
            </div>
            <div>
              The link will open up in a new tab. Your new password will be in
              effect the next time you log in.
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  title: "Reset password | Tomorrow Ready Texas",
  data() {
    return {
      lang_stanza_key: "password_forgot",
      lang_stanza: {},
      lang_info: {},
    };
  },
  methods: {
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    document.body.style.backgroundColor = "#F5F7FA !important";
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
#account-credentials-content {
  border-radius: 10px;
  height: 519px;
  max-width: 664px;
  margin-top: 26px !important;
  margin-bottom: 232px;
  background-color: transparent;
}

.border {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  border: none !important;
}

#account-credentials-form {
  height: 519px;
  max-width: 500px;
  padding: 72px 16px 252px 16px;
  margin-left: auto;
  margin-right: auto;
}

.container-sm {
  padding-left: 16px;
  padding-right: 16px;
}

h1.change-password-header {
  text-align: left;
  margin-bottom: 37px;
}

.reset-password-message {
  text-align: left;
  color: #3a3a3a;
  font-family: Lato;
  font-size: 18px;
}

.change-password-link {
  color: #1a6edb;
  margin-right: 2px;
}

.reset-password-message div:nth-child(1) {
  margin-bottom: 21px;
}
</style>
